<template>
  <div class="mt-4  px-5">


    <v-data-table
        :headers="headers"
        :items="comp_Ranges"
        sort-by="name"
        class="elevation-1"
        ref="Ranges"
    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="success"
        ><h3>Stände</h3>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon class="mr-2">mdi-map-marker</v-icon>
                Stand anlegen
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <span class="text-h5">Stand anlegen</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="newShootingRange.KurzBez"
                          label="Kurz"
                          :rules="TextRules"
                          outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="newShootingRange.LangBez"
                          label="Bezeichnung"
                          :rules="TextRules"
                          outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="newShootingRange.Places"
                          label="Bahnen"
                          :rules="TextRules"
                          outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="setRange"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn icon
                 @click="getRanges">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
            class="btn-error"
            color="error"
            @click="delRange(item)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>

      </template>

      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'ranges',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    loading: false,
    headers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Kurz', value: 'KurzBez'},
      {text: 'Name', value: 'LangBez'},
      {text: 'Bahnen', value: 'totalplaces'},
      {text: 'Einträge Schiessbuch', value: 'AnzahlSB'},
      {text: 'Action', value: 'action', sortable: false}
    ],
    newShootingRange: {
      KurzBez: null,
      LangBez: null,
      Places: null,
    },

    TextRules: [
      v => !!v || 'Eingabe erforderlich',
    ],
  }),

  computed: {
    comp_Ranges() {
      return this.$store.state.ClubApp.ShootingRanges;
    },

  },

  mounted() {
    this.getRanges();
  },

  methods: {

    getRanges() {
      this.$store.dispatch('ClubApp/getRanges')
    },
    setRange() {

      if( this.newShootingRange.KurzBez === '' || this.newShootingRange.KurzBez === null ||
          this.newShootingRange.LangBez === '' ||  this.newShootingRange.LangBez === null ||
          this.newShootingRange.Places === '' ||  this.newShootingRange.Places === null)
      {
        return
      }


      var Data = {
        StandKurzbez: this.newShootingRange.KurzBez,
        StandLangbez: this.newShootingRange.LangBez,
        totalplaces: this.newShootingRange.Places
      }
      this.$store.dispatch('ClubApp/setShootingRange', Data)
      .then(this.dialog = false);
    },
    delRange(item) {

      var Data = {
        inputDelStand: item.id
      }
      this.$store.dispatch('ClubApp/delShootingRange', Data)

    },

    initialize() {
      this.getRanges();
    },
    close() {
      this.dialog = false;
    },

  },
}
</script>

<style scoped>

</style>

